.ServicePage_area {
  position: relative;
}
.ServicePage1 {
  position: relative;
  padding: 6em 0em;
  background: #fff;
  padding-top: 10em;
}
.home_div_section1_areab {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  height: 100%;
  width: -webkit-fill-available;
  z-index: 1;
  padding: 0em 4em;
}
.dash_home_products_swiper_slide_imgb {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dash_home_products_swiper_slide_img_divb {
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  height: 700px;
  object-fit: cover;
}
.ServicePage2 {
  position: relative;
  padding: 6em 0em;
  padding-bottom: 0em;
  background: #fff8f6;
}
.ServicePage2_area {
  display: flex;
  flex-direction: column;
}
.ServicePage2_area_cont {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 2em;
}
.ServicePage2_area_cont span {
  font-weight: 700;
}

@media (max-width: 767px) {
  .dash_home_products_swiper_slide_img_divb {
    height: 350px;
  }
  .ServicePage1 {
    padding: 3em 0em;
    padding-top: 6em;
  }
  .home_div_section1_areab {
    padding: 0em 1em;
  }
  .ServicePage2 {
    padding: 2em 0em;
    padding-bottom: 0em;
  }
  .ServicePage2_area_cont {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
