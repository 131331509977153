.home_div_section1 {
  position: relative;
  height: 100dvh;
}
.gallery_section {
  position: relative;
  padding: 8em 0em;
  background: #ececec;
}
.home_div_section1_swiper {
  height: 100%;
}
.dash_home_products_swiper_slide_img {
  width: 100%;
  height: 100dvh;
  object-fit: cover;
}
.dash_home_products_swiper_slide_img_div {
  position: relative;
}
.home_div_section1_fade_bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(87deg, #000000, #0000007e);
}
.home_div_section1_area {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.home_div_section1_area_div {
  display: flex;
  height: 100%;
  color: #fff;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
}
.home_div_section1_area_div_area1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.home_div_section1_area_div_area1_txt1 {
  margin-bottom: 1em;
  width: max-content;
}
.home_div_section1_area_div_area1_txt2 {
  font-size: 72px;
  width: 70%;
  margin: auto;
  margin-bottom: 1em;
  font-weight: 500;
  text-transform: uppercase;
}
.home_div_section1_area_div_area1_txt2 span {
  color: #1f8759;
}
.home_div_section1_area_div_area1_txt2b {
  font-size: 62px;
  width: 70%;
  margin-bottom: 1em;
  font-weight: 500;
  text-transform: uppercase;
}
.home_div_section1_area_div_area1_txt2b_abt {
  font-size: 62px;
  width: 100%;
  margin-bottom: 1em;
  font-weight: 500;
  text-transform: uppercase;
}
.home_div_section1_area_div_area1_button_div {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  color: #fff;
}
.home_div_section1_area_div_area1_button_div_icon_div {
  border: solid;
  padding: 10px;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
}

.home_div_section2 {
  position: relative;
  padding: 6em 0em;
  background: #fff8f6;
}
.home_div_section2_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home_div_section2_area_1 {
  width: 100%;
}
.home_div_section2_area_1_img {
  width: 100%;
}
.home_div_section2_area_2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 4em;
}
.home_div_section2_area_2_title {
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
}
.home_div_section2_area_2_title::after {
  content: " ";
  position: absolute;
  bottom: 1px;
  width: 100px;
  height: 4px;
  border-radius: 50px;
  background: #1f875a;
  left: 0;
  /* right: 0; */
  margin: auto;
}
.home_div_section3 {
  position: relative;
  padding: 8em 0em;
  background: #fff8f6;
}
.home_div_section2_area_2_para {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 2em;
  line-height: 1.5;

  color: dimgrey;
}
.home_div_section3_area {
  display: flex;
  flex-direction: column;
}
.home_div_section3_area_1 {
  display: flex;
  flex-direction: column;
}
.home_div_section3_area_1_title {
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
}
.home_div_section3_area_1_title::after {
  content: " ";
  position: absolute;
  bottom: 1px;
  width: 100px;
  height: 4px;
  border-radius: 50px;
  background: #1f875a;
  left: 0;
  /* right: 0; */
  margin: auto;
}
.home_div_section3_area_1_para {
  width: 54%;
  font-weight: 300;
  font-size: 18px;
  color: dimgrey;
}
.home_div_section3_area_2 {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}
.home_div_section3_area_2_cont1 {
  display: flex !important;
  flex-direction: column;
  /* margin: 0px 10px; */
  width: -moz-fit-content !important;
  width: fit-content !important;
  border: solid 1px #7c9289;
  border-radius: 12px;
  padding: 0em;
}
.home_div_section3_area_2_cont1:hover .home_div_section3_area_2_cont1_img {
  transform: scale(1.2);
  transition: all 0.3s linear;
}
.home_div_section3_area_2_cont1_img_div {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 2em;
  overflow: hidden;
  background: #000;
}
.home_div_section3_area_2_cont1_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all 0.3s linear;
}
.home_div_section3_area_2_cont1_title {
  font-size: 20px;
  margin-bottom: 1em;
  white-space: nowrap;
  width: 100%;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-transform: uppercase; */
  color: #fff;
  font-weight: 600;
}
.home_div_section3_area_2_cont1_para {
  font-weight: 300;
  margin-bottom: 2em;
  font-size: 14px;
  color: #a8cbbf;
}
.home_div_section3_area_2_cont1_btn {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  color: #ffffff;
  background: #2b9768;
  padding: 10px 10px;
  width: max-content;
  font-size: 14px;
  border-radius: 10px;
}
.home_div_section3_area_2_cont1_btn_icon {
  margin-right: 2px;
  font-size: 18px !important;
  margin-bottom: 4px;
}

.feautured_in_div {
  position: relative;
  padding: 2em 0em;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.feautured_in_div_title {
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 1em;
  position: relative;
}
.feautured_in_div_title::after {
  content: " ";
  position: absolute;
  bottom: 1px;
  width: 80%;
  height: 4px;
  border-radius: 50px;
  background: #1f875a;
  left: 0;
  right: 0;
  margin: auto;
}
.feautured_in_div_img {
  width: 100px;
  margin: 0em 1em;
  filter: grayscale(1);
}
.home_div_section4 {
  position: relative;
  padding: 6em 0em;
  background: #fff8f6;
}
.home_div_section4_area {
  display: flex;
  flex-direction: column;
}
.home_div_section4_area_cont1 {
  font-size: 42px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.img_popUp {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  background: #000000b8;
  backdrop-filter: blur(5px);
  cursor: pointer;
}
.img_popUp_img {
  box-shadow: -1px 0px 20px 20px #00000087;
  width: 500px;
}
.home_div_section4_area_cont1::after {
  content: " ";
  position: absolute;
  bottom: 1px;
  width: 100px;
  height: 4px;
  border-radius: 50px;
  background: #1f875a;
  left: 0;
  /* right: 0; */
  margin: auto;
}
.home_div_section4_area_cont1_btn {
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.home_div_section4_area_cont2 {
  display: flex;
  height: 700px;
}
.home_div_section4_area_cont2_cont1 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s linear;
  position: relative;
}
.Expandiv_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #45454545;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Expandiv_div_txt {
  text-shadow: 0px 1px 5px black;
  color: #fff;
  font-size: 24px;
}
.home_div_section4_area_cont2_cont1:hover {
  transform: scale(1.07);
  box-shadow: 0px 0px 20px 15px #00000057;
  transition: all 0.3s linear;
  z-index: 1;
}
.home_div_section4_area_cont2_cont1_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_div_section4_area_cont2_cont2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.home_div_section4_area_cont2_cont2_div {
  width: 100%;
  height: 350px;
  position: relative;
  cursor: pointer;
}
.home_div_section4_area_cont2_cont2_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.3s linear;
}
.home_div_section4_area_cont2_cont2_div:hover {
  transform: scale(1.07);
  box-shadow: 0px 0px 20px 15px #00000057;
  transition: all 0.3s linear;
  z-index: 1;
}

.home_div_section5 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.home_div_section1_fade_bg_img2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(87deg, #09492d, #000000b3);
}
.home_div_section5_area {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6em 0em;
}
.home_div_section5_area_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-right: 1em;
}
.home_div_section5_area_1_div1 {
  font-size: 48px;
}
.home_div_section5_area_1_div1 span {
  color: #8ed6b6;
}
.home_div_section5_area_1_div2 {
  font-size: 18px;
  font-weight: 300;
  color: #d2d2d2;
  margin-top: 1em;
  width: 60%;
}
.home_div_section5_area_2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.home_div_section5_area_2_input_div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  color: #a0a0a0;
}
.home_div_section5_area_2_input_div_title {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

.home_div_section5_area_2_input_div_input {
  padding: 15px;
  border-radius: 8px;
  border: solid 1px #8e8e8e;
  background: #000;
  color: white;
}
.home_div_section5_area_2_input_div_txt_area {
  padding: 15px;
  border: solid 1px #8e8e8e;
  background: #000;
  border-radius: 10px;
  color: #fff;
}
.home_div_section5_area_2_btn {
  padding: 15px;
  border-radius: 12px;
  width: 200px;
  margin: auto;
  background: #e9360e;
  color: #fff;
  border: none;
}
.services_slider {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 32px !important;
}
.slick-prev {
  left: 93% !important;
  top: -15% !important;
}
.slick-next {
  right: 2% !important;
  top: -15% !important;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.15 !important;
}

.home_div_section3_area_body_cont1 {
  padding: 2em;
  min-height: 24em;
  margin-bottom: 1em;
  margin-right: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(178deg, #000000, #00000091);
  border: solid 1px #f5f5f5;
  cursor: pointer;
  width: 100%;
}
.home_div_section3_area_body_cont1_title {
  color: #759289;
}
.home_div_section3_area_body_cont1_body_text {
  display: flex;
  flex-direction: column;
}
.home_div_section3_area_body_cont1_body_text_title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #1f8759;
}
.home_div_section3_area_body_cont1_body_text_para {
  font-size: 18px;
  color: #ffffffe6;
}

.home_div_section3_area_body_cont1_icon svg {
  fill: #000;
  /* color: #000; */
  /* stroke: #000; */
}
.home_div_section3_area_body_cont1_icon svg path {
  stroke: #000;
}

.mission_section_area {
  display: flex;
  justify-content: space-between;
}
.mission_section {
  position: relative;
  padding: 4em 0em;
}
@media (max-width: 999px) {
  .home_div_section1_area_div_area1_txt2 {
    font-size: 48px;
    width: 100%;
    margin-bottom: 1em;
    font-weight: 500;
  }
  .home_div_section1_area_div_area1_txt2b {
    font-size: 48px;
    width: 100%;
    margin-bottom: 1em;
    font-weight: 500;
  }
  .home_div_section1_area_div_area1_txt2b_abt {
    font-size: 48px;
    width: 100%;
    margin-bottom: 1em;
    font-weight: 500;
  }
  .home_div_section2_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .home_div_section2_area_2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0em;
    margin-top: 2em;
  }
  .home_div_section3_area_1_para {
    width: 100%;
    font-weight: 200;
    font-size: 18px;
  }
  .home_div_section5_area {
    flex-direction: column;
  }
  .home_div_section5_area_1 {
    margin-bottom: 1em;
    margin-right: 0em;
  }
}
@media (max-width: 767px) {
  .home_div_section1_area_div_area1_txt2 {
    font-size: 38px;
  }
  .home_div_section1_area_div_area1_txt2b {
    font-size: 32px;
  }
  .home_div_section1_area_div_area1_txt2b_abt {
    font-size: 32px;
  }
  .feautured_in_div_title {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .feautured_in_div {
    padding: 1em 0em;
  }
  .home_div_section2 {
    padding: 2em 0em;
  }
  .home_div_section2_area_2_title {
    font-size: 32px;
  }
  .home_div_section3 {
    padding: 4em 0em;
  }
  .home_div_section3_area_1_title {
    font-size: 32px;
  }
  .home_div_section4 {
    padding: 2em 0em;
  }
  .home_div_section4_area_cont1 {
    font-size: 32px;
  }
  .home_div_section5_area {
    padding: 2em 0em;
  }
  .home_div_section5_area_1_div1 {
    font-size: 32px;
  }
  .home_div_section5_area_1_div2 {
    width: 100%;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .home_div_section2_area_1 {
    width: 100%;
  }
  .home_div_section4_area_cont2 {
    height: 300px;
  }

  .home_div_section4_area_cont2_cont2_div {
    height: 150px;
  }
  .mission_section_area {
    flex-direction: column;
  }
  .home_div_section3_area_body_cont1 {
    margin-bottom: 1em;
    margin-right: 0em;
    width: -webkit-fill-available;
  }
  .dash_home_products_swiper_slide_img_divb {
    height: 350px;
  }
  .ServicePage1 {
    padding: 3em 0em;
    padding-top: 8em;
  }
  .home_div_section1_areab {
    padding: 0em 2em;
  }
  .home_div_section2_area_2_para {
    font-size: 16px;
  }
  .home_div_section3_area_1_para {
    font-size: 16px;
  }
  .home_div_section5_area_1_div2 {
    font-size: 16px;
  }
}
