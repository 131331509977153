* {
  margin: 0;
  padding: 0;
  font-family: "Utendo", sans-serif;
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: "Utendo", sans-serif;
  -webkit-font-smoothing: antialiased !important;
}
body::-webkit-scrollbar {
  background: #ffffff !important;
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  background: #2c2c2c !important;
  border-radius: 50px;
}
.custom_container {
  width: auto;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0em 1em;
  height: 100%;
}
button {
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
button:hover {
  transform: translate3d(0, -5px, 0);
  transition: 0.3s ease-in-out;
}
html {
  scroll-behavior: smooth;
  transition: 2s ease-in;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(234 234 234) inset !important;
}
input:focus-visible {
  outline: none;
  border: solid;
  border-color: #ff562387;
  border-width: 1px;
  box-shadow: 0px 0px 1px 4px #ff562387;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
input:-internal-autofill-selected {
  background-color: #222035a8 !important;
}
::selection {
  background-color: #ff562387;
  color: #fff;
}
button:focus {
  outline: unset !important;
}

.App {
  display: flex;
  flex-direction: column;
  /* position: relative; */
}
.container {
  width: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.container2 {
  width: -webkit-fill-available;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 0.90em;
  height: 100%;
}
.custom2_container {
  width: auto;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 15px;
}
.slick-dots {
  bottom: -40px !important;
}
@media (max-width: 999px) {
  .container {
    max-width: 720px;
  }
  .custom2_container {
    max-width: 720px;
  }
  .container2 {
    max-width: 720px;
  }
}

/* =================== */
/* =================== */
/* =================== */
/* =================== */
/* =================== */
.Modal2_div {
  position: fixed;
  top: 0%;
  width: -webkit-fill-available;
  height: 100svh;
  background: #000000ab;
  left: 0;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  z-index: 1000;
  padding: 1em;

  backdrop-filter: blur(6px);
}

.Modal2_div_area {
  background: #fff;
  padding: 2em;
  width: 100%;
  max-width: 30em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 0px 16px 0px #060606c7;
  animation: fadeShow 0.3s linear;
  position: relative;
  z-index: 1;
}

.dark .Modal2_div_area {
  background: #232326;
}

@keyframes fadeShow {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Modal2_div1_head {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.dark .Modal2_div1_head {
  color: #fff;
}

.Modal2_div2 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 10px;
  margin-top: 10px;
}

.Modal2_div2_div1_area {
  width: 100%;
}

.Modal2_div2_div1_area_btn {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #e8e8e8;
  border: solid 1px #d0d0d0;
  flex-direction: row-reverse;
}

.Modal2_div2_div1_area_btn:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  /* filter: grayscale(1); */
}

.dark .Modal2_div2_div1_area_btn {
  background: #39393c;
  border: solid 1px #4f4f55;
  color: #fff;
}

.Modal2_div2_div1_area_btn_area {
  display: flex;
  align-items: center;
}

.Modal2_div2_div1_area_btn_area_txt {
  margin-left: 4px;
}

.Modal2_div_area_span {
  margin-top: 1em;
  font-size: 12px;
  color: #747474;
  font-weight: 400;
}

.dark .Modal2_div_area_span {
  color: #b6978e;
}

.Modal2_div1_head_icon {
  font-size: 33px !important;
  cursor: pointer;
}

.Modal2_div_closeDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: none;
}
.shimmer {
  background-image: linear-gradient(
    to right,
    #d5d4d4 8%,
    #ffffff 18%,
    #d9d9d9 33%
  ) !important;
}
.shimmer-button {
  margin-bottom: 0 !important;
}

input[type="checkbox"] {
  display: none;
}
.checkBox_agree_div_body_txt {
  margin-left: 10px;
}
.checkBox_agree_div_body_label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkBox_agree_div_body_label:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid #ccc;
  border-radius: 7px;
}

input[type="checkbox"]:checked + .checkBox_agree_div_body_label:before {
  content: "\2713";
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  background: #fd5623;
  /* height: 24px; */
  /* line-height: 1.1; */
}
#react-qrcode-logo {
  height: 300px !important;
  border-radius: 10px;
  width: 100% !important;
  max-width: 300px !important;
  max-height: 100% !important;
}
.plan_swap_modal_div_icon {
  background: #fff;
  border-radius: 50px;
  margin-top: 1em;
  padding: 5px;
  cursor: pointer;
  font-weight: 900;
}
/* =================== */
/* =================== */
/* =================== */
/* =================== */
/* =================== */
/* =================== */
